<template>
  <div>
    <Render
      :options="preview.jsonSchema"
      :form-guid="preview.form.guid"
      is-thank-you
      is-preview
    />
  </div>
</template>

<script>
import Render from 'common/components/render/Render';
import metaRenderMixin from 'common/mixins/metaRenderMixin';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'PreviewThankYouPage',
  components: { Render },
  mixins: [metaRenderMixin],
  preFetch({ store, currentRoute }) {
    return store.dispatch('preview/getPreview', currentRoute.params.pageGuid);
  },
  computed: {
    ...mapState('preview', ['preview']),
  },
  methods: {
    ...mapActions('preview', ['getPreview']),
  },
};
</script>
